<template>
  <div class="echarts-box">
    <div class="NetworkHash" :style="{height: '292px'}">
      <div class="title">
        <span>{{ store.state.language ? '周期' : 'Time' }}</span>
        <span>{{ store.state.language ? '哈希率' : 'Hashrate' }}</span>
      </div>
      <div class="line"></div>
      <div class="contentData">
        <div class="dataItem">
          <span>{{ store.state.language ? `3 小时` : `3 hours` }}</span><span>{{ averageLast3Hours.toFixed(2) }} PH/s</span>
        </div>
        <div class="dataItem">
          <span>{{ store.state.language ? `12 小时` : `12 hours` }}</span><span>{{ averageLast12Hours.toFixed(2) }} PH/s</span>
        </div>
        <div class="dataItem">
          <span>{{ store.state.language ? `24 小时` : `24 hours` }}</span><span>{{averageLast24Hours.toFixed(2)}} PH/s</span>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="echartsItem" ref="multipleXAxes" :style="{height: '292px'}"></div>
  </div>
</template>
<script setup>
import * as echarts from "echarts";
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {getNetworkHashrateChartData} from "@/common/api";

let echart = echarts;
const store = useStore();
const multipleXAxes = ref(null);
const ELegendData = ref([]);
const ExAxisData = ref([]);
const averageLast3Hours = ref(0); //前3小时的平均哈希值
const averageLast12Hours = ref(0); //前12小时的平均哈希值
const averageLast24Hours = ref(0); //前24小时的平均哈希值
watch(
    () => store.state.language,
    (newVal) => {
      if (newVal) {
        ELegendData.value = [`全网哈希率`];
      } else {
        ELegendData.value = ['Network hashrate'];
      }
      nextTick(() => {
        initChart(); // 手动触发重新计算宽度
      });
    }
);
onMounted(() => {
  Getlanguage();
  getNetworkHashrateData();

});

onUnmounted(() => {
  echart.dispose;
});

const getNetworkHashrateData = () => {
  getNetworkHashrateChartData({}, res => {
    if (res.data.header.code == 0) {
      let targetData = res.data.body.network_hashrate_chart.data.map(item => Number(item.toFixed(2)))
      ExAxisData.value = targetData
      // 前3小时的数据
      const last3Hours = targetData.slice(-3);
      averageLast3Hours.value = last3Hours.reduce((total, num) => total + num, 0) / last3Hours.length;

// 前12小时的数据
      const last12Hours = targetData.slice(-12);
      averageLast12Hours.value = last12Hours.reduce((total, num) => total + num, 0) / last12Hours.length;

// 前24小时的数据
      const last24Hours = targetData;
      averageLast24Hours.value = last24Hours.reduce((total, num) => total + num, 0) / last24Hours.length;
      initChart()
    }
  })
}

// 基础配置一下Echarts
function initChart() {
  let chart = echart.init(multipleXAxes.value, {
    theme: 'dark',
    color: '#1bbbee', // 根据需要设置自定义颜色
    backgroundColor: 'rgba(22,30,54,0.5)',
  });
  // 把配置和数据放这里
  chart.setOption({
    grid: {
      left: '10%',        // 图表左边距离容器的距离
      right: '10%',       // 图表右边距离容器的距离
      bottom: '10%',      // 图表底部距离容器的距离
      containLabel: true  // 包含坐标轴的标签在内
    },
    legend: {
      data: ELegendData.value,
      textStyle: {
        color: "#ffffff",
        fontSize: 18
      },
      top: '5%',
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {},
      borderWidth: 0,
      textStyle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16
      },
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 设置背景颜色
      formatter: function (params) {
        let now = new Date();
        let dataIndex = params[0].dataIndex; // 获取当前悬浮的数据索引
        let time = new Date(now - dataIndex * 3600 * 1000); // 计算对应的时间
        let value = 'Hashrate：' + params[0].value + ' PH/s'; // 对应的纵坐标数值
        return time.toLocaleString() + '<br>' + value; // 返回格式化后的提示内容
      }
    },
    xAxis: {
      offset: 10,
      type: "category",
      boundaryGap: false,
      data: getHours(),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: Math.floor(ExAxisData.value.length / 4),
        textStyle: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      offset: 10,
      type: "value",
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#4a5769',
          type: 'solid',
        }
      },
      axisLabel: {
        formatter: '{value} PH/s',
        textStyle: {
          color: "#ffffff",
        },
      },
    },
    series: [
      {
        name: ELegendData.value[0],
        symbol: "circle", //隐藏点
        data: ExAxisData.value,
        type: "line",
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(27,186,236,1)'
            }, {
              offset: 1,
              color: 'rgba(27,186,236,0)'
            }]
          }
        }
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    chart.resize();
  };
};

function Getlanguage() {
  let isZh = store.state.language
  if (isZh) {
    ELegendData.value = ['全网哈希率'];
  } else {
    ELegendData.value = ['Network hashrate'];

  }
}

function getHours() {
  let now = new Date(); // 获取当前时间
  let data = []; // 横坐标数据

// 循环添加24个小时的数据
  for (let i = 0; i < 24; i++) {
    // 将小时数转换成日期格式
    let date = new Date(now - i * 3600 * 1000);
    // 将日期格式化为字符串，并添加到横坐标数据中
    data.push(date.toLocaleTimeString('en-US', {hour12: false}));
  }
  return data
}
</script>
<style lang="scss" scoped>
.echarts-box {
  width: 100%;
  //box-shadow: 0 0 12px 3px #00a6ff;
  border-radius: 15px;
  display: flex;

  div.NetworkHash {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(22, 30, 54, 0.5);
    box-shadow: 0 0 12px 3px #00a6ff;
    padding: 10px;
    border-radius: 15px;
    margin-right: 40px;

    span {
      font-family: 'Jura', sans-serif;
      font-size: 20px;
      color: #c2dae7;
      padding: 5px;

      &:nth-last-child(1) {
        color: #FFF;
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
    }

    > div.line {
      height: 1px;
      background-color: #9aaeb8
    }

    div.title {

      span {
        color: #c2dae7;
      }
    }

    div.contentData {
      display: flex;
      flex-direction: column;

      .dataItem {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  div.echartsItem {
    width: calc(100% - 300px - 40px);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 12px 3px #00a6ff;
  }
}
</style>