<template>
  <div class="echarts-box">
    <div class="NetworkHash" :style="{height: '292px'}">
      <div class="title">
        <span>{{store.state.language?'我的收益':'My Benefits'}}</span>
      </div>
      <div class="line"></div>
      <div class="contentData">
        <div class="dataItem">
          <span>{{store.state.language?'今天':'today'}}</span><span>{{ dayTotal }} KAS</span>
        </div>
        <div class="dataItem">
          <span>{{store.state.language?'本周':'This week'}}</span><span>{{ weekTotal }} KAS</span>
        </div>
        <div class="dataItem">
          <span>{{store.state.language?'本月':'This month'}}</span><span>{{ monthTotal }} KAS</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="activeWorkers">
        <span>{{store.state.language?'总收益':'total revenue'}}</span>
        <span>{{ Total }}KAS</span>
      </div>
    </div>
    <div class="echartsItem" ref="multipleXAxes" :style="{height: '292px'}"></div>
  </div>
</template>
<script setup>
import * as echarts from "echarts";
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {getGoldenBalanceList, getMemberStatisticsWallet} from "@/common/api";

let echart = echarts;
const store = useStore();
const multipleXAxes = ref(null);
const ELegendData = ref([]);
const ExAxisData = ref([]);
const dayTotal = ref(0);//今天收获
const weekTotal = ref(0);//本周收获
const monthTotal = ref(0);//本月收获
const Total = ref(0)//总收益
watch(
    () => store.state.language,
    (newVal) => {
      if (newVal) {
        ELegendData.value = ['我的收益'];
      } else {
        ELegendData.value = ['My Benefits'];
      }
      nextTick(() => {
        initChart(); // 手动触发重新计算宽度
      });
    }
);
onMounted(() => {
  getGoldenBalanceListSSR();
  getWallet();
  Getlanguage();
});

onUnmounted(() => {
  echart.dispose;
});
// 获取当前日期
const today = new Date();

// 获取前七天的日期
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

// 获取前一个月的日期
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(today.getMonth() - 1);

// 转换日期为年/月/日格式
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};

// 格式化日期
const formattedToday = formatDate(today);
const formattedSevenDaysAgo = formatDate(sevenDaysAgo);
const formattedOneMonthAgo = formatDate(oneMonthAgo);
// console.log(formattedToday,formattedSevenDaysAgo,formattedOneMonthAgo)
const getGoldenBalanceListSSR = () => {
  getGoldenBalanceList({
    pageNumber: 9999,
    sortTypeTime: 2,
    balanceType:20,
  }, res => {
    ExAxisData.value=[]
    dayTotal.value=0;
    weekTotal.value=0;
    monthTotal.value=0;
    res.data.body.data.rows.forEach(item=>{
      let day = item.changeTime==new Date(formattedToday).getTime() //今天的
      let SevenDays = item.changeTime>=new Date(formattedSevenDaysAgo).getTime() //七天前的
      let Month = item.changeTime>=new Date(formattedOneMonthAgo).getTime() //一个月前的
      if(day){
        dayTotal.value+=Math.floor(item.amount)
      }
      if(SevenDays){
        weekTotal.value+=Math.floor(item.amount)
      }
      if(Month){
        ExAxisData.value.push(item.amount)
        monthTotal.value+=Math.floor(item.amount)
      }
    })
    initChart();
    // console.log(ExAxisData.value, 'res')
  })
}


// 获取总收入
const getWallet = () =>{
  getMemberStatisticsWallet({},res=>{
    Total.value = Math.floor(res.data.body.memberStatisticsDto.goldenTotal)
  })
}
// 基础配置一下Echarts
function initChart() {
  let chart = echart.init(multipleXAxes.value, {
    theme: 'dark',
    color: '#1bbbee', // 根据需要设置自定义颜色
    backgroundColor: 'rgba(22,30,54,0.5)',
  });
  // 把配置和数据放这里
  chart.setOption({
    grid: {
      left: '10%',        // 图表左边距离容器的距离
      right: '10%',       // 图表右边距离容器的距离
      bottom: '10%',      // 图表底部距离容器的距离
      containLabel: true  // 包含坐标轴的标签在内
    },
    legend: {
      data: ELegendData.value,
      textStyle: {
        color: "#ffffff",
        fontSize: 18
      },
      top: '5%',
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {},
      borderWidth: 0,
      textStyle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16
      },
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 设置背景颜色
      formatter: function (params) {
        return params[0].axisValue + `<br/>` + params[0].data + ' KAS'; // 返回格式化后的提示内容
      }
    },
    xAxis: {
      type: "category",
      offset: 10,
      boundaryGap: false,
      data: getDays(),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        // interval: Math.floor(ExAxisData.value.length / 4),
        textStyle: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      offset: 10,
      type: "value",
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#4a5769',
          type: 'solid',
        }
      },
      axisLabel: {
        formatter: '{value} KAS',
        textStyle: {
          color: "#ffffff",
        },
      },
    },
    series: [
      {
        name: ELegendData.value[0],
        symbol: "circle", //隐藏点
        data: ExAxisData.value,
        type: "line",
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(27,186,236,1)'
            }, {
              offset: 1,
              color: 'rgba(27,186,236,0)'
            }]
          }
        }
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    chart.resize();
  };
}
function Getlanguage() {
  let isZh = store.state.language
  if (isZh) {
    ELegendData.value = ['我的收益'];
  } else {
    ELegendData.value = ['My Benefits'];
  }
}

function getDays() {
  let now = new Date(); // 获取当前时间
  let data = []; // 横坐标数据

  // 循环添加前30天的日期数据
  for (let i = 29; i >= 0; i--) {
    let date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000); // 计算对应的日期（当前时间减去相应的天数）
    let month = String(date.getMonth() + 1).padStart(2, '0'); // 计算月份，并转换为两位数的字符串形式
    let day = String(date.getDate()).padStart(2, '0'); // 计算日期，并转换为两位数的字符串形式
    data.push(month + '-' + day); // 将日期格式化为字符串，并添加到横坐标数据中
  }

  return data;
}
</script>
<style lang="scss" scoped>
.echarts-box {
  width: 100%;
  //box-shadow: 0 0 12px 3px #00a6ff;
  border-radius: 15px;
  display: flex;

  div.NetworkHash {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(22, 30, 54, 0.5);
    box-shadow: 0 0 12px 3px #00a6ff;
    padding: 10px;
    border-radius: 15px;
    margin-right: 40px;

    span {
      font-family: 'Jura', sans-serif;
      font-size: 20px;
      color: #c2dae7;
      padding: 5px;

      &:nth-last-child(1) {
        color: #FFF;
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
    }

    > div.line {
      height: 1px;
      background-color: #9aaeb8
    }

    div.title {

      span {
        color: #c2dae7;
      }
    }

    div.contentData {
      display: flex;
      flex-direction: column;

      .dataItem {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  div.echartsItem {
    width: calc(100% - 300px - 40px);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 12px 3px #00a6ff;
  }
}
</style>