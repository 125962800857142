<template>
  <div class="appbox">
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="768px" autoplay>
        <el-carousel-item
            v-for="(item, index) in store?.state?.nav?.homeSwiper"
            :key="index"
        >
          <img :src="item.picturePath" alt=""/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="Echarts">
      <div class="echarts">
        <multipleXAxes/>
      </div>
      <div v-if="isIncome" class="echarts">
        <multipleXAxes1/>
      </div>
    </div>

    <!-- 推荐商品列表模块 -->
    <!--    <div-->
    <!--        class="recommendContent"-->
    <!--        v-for="(item, index) in data.GoodsRecommendList"-->
    <!--        :key="index"-->
    <!--        v-if="data.GoodsRecommendList.length!=0"-->
    <!--    >-->
    <!--      <div class="recommendGoodsContent">-->
    <!--        &lt;!&ndash;        上面的&ndash;&gt;-->
    <!--        <div class="recommendGoodsContentUp">-->
    <!--          <div class="recommendGoodsContentUpImg">-->
    <!--            <img :src="item.goodsShopLargerImage" alt=""/>-->
    <!--          </div>-->
    <!--          <div class="recommendGoodsContentUpTitle">-->
    <!--            <ul class="recommendGoodsContentUpTitle_ul">-->
    <!--              <li-->
    <!--                  v-for="(item1, index) in item.goodsShopShortDescription"-->
    <!--                  :key="index"-->
    <!--              >-->
    <!--                {{ item1 }}-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        &lt;!&ndash;        下面的&ndash;&gt;-->
    <!--        <div class="recommendGoodsContentDown">-->
    <!--          <div class="recommendGoodsContentDownTitle">-->
    <!--            <ul class="recommendGoodsContentDownTitle_ul">-->
    <!--              <li-->
    <!--                  v-for="(item1, index) in item.goodsShopUnitDescription"-->
    <!--                  :key="index"-->
    <!--              >-->
    <!--                {{ item1 }}-->
    <!--              </li>-->
    <!--            </ul>-->
    <!--          </div>-->
    <!--          <div class="recommendGoodsContentDownImg">-->
    <!--            <img :src="item.goodsShopLittleImage" alt=""/>-->
    <!--            <div class="recommendGoodsContentDownBtn">-->
    <!--              <div-->
    <!--                  class="shoppingCartBtn"-->
    <!--                  @click="addGoodsToCartfun(item)"-->
    <!--                  style="cursor: pointer;"-->
    <!--              >-->
    <!--                &lt;!&ndash; <div class="quantityInCart">{{ data.goodsNumber }}</div> &ndash;&gt;-->
    <!--                {{ store.state.language ? '加入购物车' : 'Add Cart' }}-->
    <!--              </div>-->
    <!--              <div class="buyBtn" @click="buyItNow(item)" style="cursor: pointer;">-->
    <!--                {{ store.state.language ? '立即购买' : 'BUY NOW' }}-->
    <!--              </div>-->

    <!--              <div class="recommendGoodsContentDownBtnTetle">-->
    <!--                <el-tooltip class="item" effect="light"-->
    <!--                            :content="store.state.language?`回本周期:${item.highthMin}-${item.highthMax}天, ${item.incomeTotal} U/当天收益`:`Return period:${item.highthMin}-${item.highthMax} days, ${item.incomeTotal} U/daily income`"-->
    <!--                            placement="right">-->
    <!--                  <div class="itemSSR001" :style="{fontSize:store.state.language?'16px':'12px'}">-->
    <!--                    {{-->
    <!--                      store.state.language ? `回本周期:${item.highthMin}-${item.highthMax}天` : `Return period: ${item.highthMin}-${item.highthMax} days`-->
    <!--                    }},-->
    <!--                    {{ item.incomeTotal }}{{ store.state.language ? ' U/当天收益' : ' U/daily income' }}-->
    <!--                  </div>-->
    <!--                </el-tooltip>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    商品推荐-->
    <div
        class="recommendContent"
        v-for="(item, index) in data.GoodsRecommendList"
        :key="index"
        v-if="data.GoodsRecommendList.length!=0"
    >
      <div class="recommendGoodsContent">

        <!--        上面的-->
        <!--        <div v-if="store.state.language" class="recommendGoodsContentUp">-->
        <!--          <div class="recommendGoodsContentUpImg">-->
        <!--            <img :src="item.goodsShopLargerImage" alt=""/>-->
        <!--            <div class="recommendGoodsContentDownBtn">-->
        <!--              <div-->
        <!--                  class="shoppingCartBtn"-->
        <!--                  @click="addGoodsToCartfun(item)"-->
        <!--                  style="cursor: pointer;"-->
        <!--              >-->
        <!--                &lt;!&ndash; <div class="quantityInCart">{{ data.goodsNumber }}</div> &ndash;&gt;-->
        <!--                {{ store.state.language ? '加入购物车' : 'Add Cart' }}-->
        <!--              </div>-->
        <!--              <div class="buyBtn" @click="buyItNow(item)" style="cursor: pointer;">-->
        <!--                {{ store.state.language ? '立即购买' : 'BUY NOW' }}-->
        <!--              </div>-->

        <!--              <div class="recommendGoodsContentDownBtnTetle">-->
        <!--                <el-tooltip class="item" effect="light"-->
        <!--                            :content="store.state.language?`回本周期:${item.highthMin}-${item.highthMax}天, ${item.incomeTotal} U/当天收益`:`Return period:${item.highthMin}-${item.highthMax} days, ${item.incomeTotal} U/daily income`"-->
        <!--                            placement="right">-->
        <!--                  <div class="itemSSR001" :style="{fontSize:store.state.language?'16px':'12px'}">-->
        <!--                    {{-->
        <!--                      store.state.language ? `回本周期:${item.highthMin}-${item.highthMax}天` : `Return period: ${item.highthMin}-${item.highthMax} days`-->
        <!--                    }},-->
        <!--                    {{ item.incomeTotal }}{{ store.state.language ? ' U/当天收益' : ' U/daily income' }}-->
        <!--                  </div>-->
        <!--                </el-tooltip>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--          </div>-->
        <!--          <div class="recommendGoodsContentUpTitle">-->
        <!--            <ul class="recommendGoodsContentUpTitle_ul">-->
        <!--              <li-->
        <!--                  v-for="(item1, index) in item.goodsShopShortDescription"-->
        <!--                  :key="index"-->
        <!--              >-->
        <!--                {{ item1 }}-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->


        <!--        下面的-->
        <div class="recommendGoodsContentDown">
          <div class="recommendGoodsContentDownTitle">
            <ul v-if="!store.state.language" class="recommendGoodsContentDownTitle_ul">
              <li
                  v-for="(item1, index) in item.goodsShopUnitDescription"
                  :key="index"
              >
                {{ item1 }}
              </li>
            </ul>
            <ul v-else class="recommendGoodsContentUpTitle_ul">
              <li
                  v-for="(item1, index) in item.goodsShopShortDescription"
                  :key="index"
              >
                {{ item1 }}
              </li>
            </ul>
          </div>
          <div class="recommendGoodsContentDownImg">
            <img :src="item.goodsShopLittleImage" alt=""/>
            <div class="recommendGoodsContentDownBtn">
              <div
                  class="shoppingCartBtn"
                  @click="addGoodsToCartfun(item)"
                  style="cursor: pointer;"
              >
                <!-- <div class="quantityInCart">{{ data.goodsNumber }}</div> -->
                {{ store.state.language ? '加入购物车' : 'Add Cart' }}
              </div>
              <div class="buyBtn" @click="buyItNow(item)" style="cursor: pointer;">
                {{ store.state.language ? '立即购买' : 'BUY NOW' }}
              </div>

              <div class="recommendGoodsContentDownBtnTetle">
                <el-tooltip class="item" effect="light"
                            :content="store.state.language?`回本周期:${item.highthMin}-${item.highthMax}天, ${item.incomeTotal} U/当天收益`:`Return period:${item.highthMin}-${item.highthMax} days, ${item.incomeTotal} U/daily income`"
                            placement="right">
                  <div class="itemSSR001" :style="{fontSize:store.state.language?'16px':'12px'}">
                    {{
                      store.state.language ? `回本周期:${item.highthMin}-${item.highthMax}天` : `Return period: ${item.highthMin}-${item.highthMax} days`
                    }},
                    {{ item.incomeTotal }}{{ store.state.language ? ' U/当天收益' : ' U/daily income' }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-empty :image-size="200"/>
    </div>
    <div class="et_pb_section">
      <div class="center">
        <div class="pb_section">
          <div class="img">
            <a href="https://kaspa.org/" target="_blank"><img src="../../../assets/img/Kaspa-Icon-Dark-Green-glow.webp"
                                                              alt=""></a>
          </div>
          <div class="a_href">
            <a href="https://kaspa.org/" target="_blank">
              <span>{{ store.state.language ? 'Kaspa官网' : 'Kaspa official website' }}</span>
              <span>{{ store.state.language ? 'kaspa.org' : 'kaspa.org' }}</span>
            </a>
          </div>
        </div>
        <div class="pb_section">
          <div class="img">
            <a href="https://explorer.kaspa.org/" target="_blank">
              <img src="../../../assets/img/k-icon-glow.png" alt="">
            </a>
          </div>
          <div class="a_href">
            <a href="https://explorer.kaspa.org/" target="_blank">
              <span>{{ store.state.language ? 'KASPA浏览器' : 'KASPA explorer' }}</span>
              <span>{{ store.state.language ? 'explorer.kaspa.org' : 'explorer.kaspa.org' }}</span>
            </a>
          </div>
        </div>
        <div class="pb_section">
          <div class="img">
            <a href="https://wallet.kaspanet.io/" target="_blank">
              <img src="../../../assets/img/kaspa-fwallet-icons.webp" alt="">
            </a>
          </div>
          <div class="a_href">
            <a href="https://wallet.kaspanet.io/" target="_blank">
              <span>{{ store.state.language ? 'Kaspa官网钱包' : 'Kaspa Official website wallet' }}</span>
              <span>{{ store.state.language ? 'wallet.kaspanet.io' : 'wallet.kaspanet.io' }}</span>
            </a>
          </div>
        </div>
        <div class="pb_section">
          <div class="img">
            <a href="https://onekey.so/" target="_blank">
              <img src="../../../assets/img/onekey_logo.png" alt="">
            </a>
          </div>
          <div class="a_href">
            <a href="https://onekey.so/" target="_blank">
              <span>{{ store.state.language ? 'Onekey硬件钱包' : 'Onekey Hardware wallet' }}</span>
              <span>{{ store.state.language ? 'onekey.so' : 'onekey.so' }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="EXCHANGES">
      <div class="center">
        <div class="title">{{ store.state.language ? 'KASPA交易所' : 'KASPA EXCHANGES' }}</div>
        <a href="https://www.kucoin.com/" target="_blank"><img src="../../../assets/img/kucoin-logo.png" alt=""></a>
        <a href="https://www.mexc.com/exchange/KAS_USDT" target="_blank"><img src="../../../assets/img/MEXC-White.png"
                                                                              alt=""></a>
        <a href="https://www.coinex.com/exchange/KAS-USDT" target="_blank"><img
            src="../../../assets/img/coinEX-White.png"
            alt=""></a>
        <a href="https://www.digifinex.com/en-ww/trade/USDT/KAS" target="_blank"><img
            src="../../../assets/img/digifinexwhite.png.png" alt=""></a>
      </div>
    </div>
  </div>
</template>
<script setup>
import multipleXAxes from "@/components/echarts/multipleXAxes.vue";
import multipleXAxes1 from "@/components/echarts/multipleXAxes1.vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {
  addGoodsToCart,
  getCartGoodsList,
  getCartGoodsTotal,
  getGoldenBalanceList,
  getGoodsRecommendList,
  getGoodsShopDetail,
  getMemberPaywayList,
  getOneMemberDetail,
  standardCreateOneMemberOrder,
} from "@/common/api.js";
import {inject, onMounted, reactive, ref} from "vue";
import {ElMessage} from "element-plus";
import Common from "@/common/common.js";
import common from "@/common/common";
import axios from "axios";

const store = useStore();
const router = useRouter();
const login = inject('login')
const switchEcharts = ref(false)
const toPage = () => {
  router.push("/trend");
};
const data = reactive({
  num: 0,
  QTYnumber: 0,
  QTY: 1,
  goods: {},
  goodsList: [],
  value: {},
  tableData: [],
  GoodsRecommendList: [],
  orderForGoodsId: "",
  email: "",
  periodType: 0,
  periodNumber: 0,
  memberPaywayList: [],
  goodsNumber: 0,
});

store.dispatch('updateCartNumber')
onMounted(() => {
  getGoldenBalanceListSSR()
});
const isIncome = ref(false)
const monthTotal = ref(0);//本月收获
// 判断有没有收益
const oneMonthAgo = new Date();
// 获取前一个月的日期
const today = new Date();
oneMonthAgo.setMonth(today.getMonth() - 1);

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};
const formattedOneMonthAgo = formatDate(oneMonthAgo);
const getGoldenBalanceListSSR = () => {
  getGoldenBalanceList({
    pageNumber: 9999,
    sortTypeTime: 2,
    balanceType: 20,
  }, res => {
    isIncome.value = res.data.body.data.rows.some(row => row.balanceType === 20);
    monthTotal.value = 0;
    res.data.body.data.rows.forEach(item => {
      let Month = item.changeTime >= new Date(formattedOneMonthAgo).getTime() //一个月前的
      if (Month) {
        monthTotal.value += Math.floor(item.amount)
        monthTotal > 0 ? isIncome.value = true : isIncome.value = false
      }else{
        isIncome.value=false
      }
    })
  })
}
//立即购买
const buyItNow = async (item) => {
  if (!window.localStorage.getItem('sessionID')) {
    login()
    return
  }

  if (data.email) {
    //获取会员支付账户列表
    getMemberPaywayList(
        {
          isValid: "1",
          isDefault: 1,
        },
        (res) => {
          if (res.data.header.code == 0) {
            var getMemberPaywayList = res.data.body.data.rows;
            console.log(getMemberPaywayList);
            var memberPaywayList = [];
            for (let index = 0; index < getMemberPaywayList.length; index++) {
              if (getMemberPaywayList[index].bindingDate) {
                memberPaywayList.push(getMemberPaywayList[index].memberPaywayID);
              }
            }
            data.memberPaywayList = memberPaywayList;
            if (data.memberPaywayList.length > 0) {
              getGoodsShopDetail(
                  {
                    goodsShopID: item.goodsShopID,
                  },
                  (res) => {
                    if (res.data.header.code == 0) {
                      // console.log(res.data.body.data.periodType);
                      data.periodType = res.data.body.data.periodType;
                      data.periodNumber = res.data.body.data.periodNumber;
                      standardCreateOneMemberOrder(
                          {
                            goodsShopID: item.goodsShopID, //商品id
                            orderType: 33,
                            byCart: 0,
                            periodType: data.periodType, //3年   1 日   2月
                            periodNumber: data.periodNumber, //周期个数
                            goodsQTY: 1, //商品数量
                            periodQTY: 1, //商品周期数
                            price: item.realPrice, //价钱
                            payFrom: 21, //10现金支付    1钱包支付    12金币支付    2第三方支付
                            memberPaywayID: data.memberPaywayList[0],
                          },
                          (res) => {
                            if (res.data.header.code == 0) {
                              router.push(
                                  `/orderPay?memberOrderID=${res.data.body.memberOrderID}`
                              );
                            }
                          }
                      );
                    } else {
                      ElMessage({
                        message: res.data.header.msg,
                        type: "error",
                      });
                    }
                  }
              );
            } else {
              if (store.state.language) {
                if (store.state.language) {
                  ElMessage({
                    message: "请添加kas地址，如已经添加请等待审核通过",
                    type: "error",
                  });
                } else {
                  ElMessage({
                    message: 'Please add the kas address. If it has already been added, please wait for approval',
                    type: 'success',
                  })
                }

              } else {
                ElMessage({
                  message: 'Please add the kas address. If it has already been added, please wait for approval',
                  type: 'success',
                })
              }

            }
          } else {
            ElMessage({
              message: res.data.header.msg,
              type: "error",
            });
          }
        }
    );
  } else {
    ElMessage({
      message: "请先绑定邮箱",
      type: "error",
    });
  }
  await addGoodsToCartfun(item)
  await router.push("/shopCar")
};

//添加购物车
const addGoodsToCartfun = (req) => {
  if (!window.localStorage.getItem('sessionID')) {
    login()
    return
  }
  if (data.email) {
    var addGoodsShopID = req.goodsShopID;
    getGoodsShopDetail(
        {
          goodsShopID: addGoodsShopID,
        },
        (res) => {
          if (res.data.header.code == 0) {
            data.periodType = res.data.body.data.periodType;
            data.periodNumber = res.data.body.data.periodNumber;
            getCartGoodsList({}, (res) => {
              if (res.data.header.code == 0) {
                var CartGoodsList = res.data.body.data.rows;
                if (CartGoodsList.length <= 0) {
                  console.log(data.periodNumber);
                  addGoodsToCart(
                      {
                        goodsShopID: addGoodsShopID, //商品id
                        QTY: 1, //商品数量
                        salesUnitQTY: 1, //销售单位数量
                        periodType: data.periodType,
                        periodNumber: data.periodNumber,
                      },
                      (res) => {
                        if (res.data.header.code === 0) {
                          ElMessage({
                            message: "添加购物车成功",
                            type: "success",
                          });
                          memberCartGoodsNum()
                          store.dispatch('updateCartNumber')
                        } else {
                          ElMessage({
                            message: res.data.header.msg,
                            type: "error",
                          });
                        }
                      }
                  );
                } else {
                  var shopIdArr = [];
                  for (let index = 0; index < CartGoodsList.length; index++) {
                    if (addGoodsShopID == CartGoodsList[index].goodsShopID) {
                      shopIdArr.push(CartGoodsList[index].goodsShopID);
                    }
                  }
                  console.log(shopIdArr);
                  if (shopIdArr.length <= 0) {
                    addGoodsToCart(
                        {
                          goodsShopID: addGoodsShopID, //商品id
                          QTY: 1, //商品数量
                          salesUnitQTY: 1, //周期数量
                          periodType: data.periodType,
                          periodNumber: data.periodNumber,
                        },
                        (res) => {
                          if (res.data.header.code === 0) {
                            ElMessage({
                              message: "添加购物车成功",
                              type: "success",
                            });
                            memberCartGoodsNum()
                            store.dispatch('updateCartNumber')
                          } else {
                            ElMessage({
                              message: res.data.header.msg,
                              type: "error",
                            });
                          }
                        }
                    );
                  } else {
                    console.log(22);
                    if (store.state.language) {
                      ElMessage({
                        message: "该商品已经加入过购物车",
                        type: "error",
                      });
                    } else {
                      ElMessage({
                        message: 'This product has already been added to the shopping cart',
                        type: 'error',
                      })
                    }

                  }
                }
              }
            });
          } else {
            ElMessage({
              message: res.data.header.msg,
              type: "error",
            });
          }
        }
    );

  } else {
    ElMessage({
      message: "请先去绑定邮箱",
      type: "error",
    });
  }
};
//推荐商品列表
const GoodsRecommendList = () => {
  getGoodsRecommendList(
      {
        // pageNumber:99999999,
        // currentPage:2,
        navigatorID: Common.navIdList.RecommendedNavigationId,
      },
      (res) => {
        if (res.data.header.code == 0) {
          // data.GoodsRecommendList = res.data.body.data.rows;
          var GoodsRecommendListDetail = res.data.body.data.rows;
          for (let index = 0; index < GoodsRecommendListDetail.length; index++) {
            var goodsDetail = {};
            goodsDetail.goodsShopShortDescription =
                GoodsRecommendListDetail[index].goodsShopShortDescription.split(
                    "，"
                );
            goodsDetail.goodsShopUnitDescription =
                GoodsRecommendListDetail[index].goodsShopUnitDescription.split(
                    "，"
                );
            goodsDetail.goodsShopLargerImage =
                GoodsRecommendListDetail[index].goodsShopLargerImage;
            goodsDetail.goodsShopLittleImage =
                GoodsRecommendListDetail[index].goodsShopLittleImage;
            goodsDetail.goodsShopID = GoodsRecommendListDetail[index].goodsShopID;
            goodsDetail.realPrice = GoodsRecommendListDetail[index].realPrice;
            goodsDetail.incomeTotal = GoodsRecommendListDetail[index].incomeTotal;
            goodsDetail.highthMin = GoodsRecommendListDetail[index].highthMin * 30;
            goodsDetail.highthMax = GoodsRecommendListDetail[index].highthMax * 30;
            data.GoodsRecommendList.push(goodsDetail);
            // console.log(data.GoodsRecommendList)
          }

        } else {
          ElMessage({
            message: res.data.header.msg,
            type: "error",
          });
        }
      }
  );
};
//获取会员详细信息
const OneMemberDetail = () => {
  getOneMemberDetail({}, (res) => {

    // console.log()
    if (res.data.header.code == 0) {
      data.email = res.data.body.email;
    }
  });
};
//获取购物车数量
const memberCartGoodsNum = () => {
  getCartGoodsTotal({}, res => {
    if (res.data.header.code == 0) {
      // console.log(res.data.body.data.goodsNumber)
      data.goodsNumber = res.data.body.data.goodsNumber
    }
  })
}
GoodsRecommendList();
OneMemberDetail();
memberCartGoodsNum()
</script>
<style lang="scss" scoped>
.appbox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.swiper {
  width: 100%;
  // margin: 0 auto;
  position: relative;

  img {
    width: 100%;
    height: 768px;
  }
}

.Echarts {
  width: 100%;
  backdrop-filter: saturate(150%) blur(3px);
  background-image: url("../../../assets/img/background-petra-2.png");
  background-color: rgba(0, 0, 0, .8);

  h2 {
    width: calc(100% - 40px);
    margin: 30px auto;
    height: 2px;
    box-shadow: 0 0 12px 3px #00a6ff;
    background: #00a6ff;
  }
}

.echarts {
  width: calc(100% - 40px);
  padding: 20px;
  box-sizing: content-box;

  .echartsHeader {
    width: 100%;
    //height: 99px;
    //background: rgba(16, 87, 185, 0.58);
    background: rgba(22, 30, 54, 0.8);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0 12px 3px #00a6ff;
    border-radius: 15px;

    span {
      font-size: 30px;
      color: #ffffff;
      line-height: 23px;
      padding-top: 23px;
      text-align: center;
      cursor: pointer;
    }

    .selectEcharts {
      font-weight: bold;
    }
  }

  .echartsTable,
  .echartsMessage {
    width: 100%;
    padding-top: 26px;
    display: flex;
    justify-content: space-around;
    color: #ffffff;
    margin-bottom: 20px;

    li {
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      position: relative;
    }

    li div {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url(@/assets/img/echartsRed.png);
      background-size: contain;
      -webkit-animation: spin 4s linear 4s 5 alternate;
      animation: spin 4s linear infinite;
    }

    li:first-child div {
      background-image: url(@/assets/img/echartsBlue.png);
    }

    li:last-child div {
      background-image: url(@/assets/img/echartsYellow.png);
    }
  }

  .echartsMessage li {
    width: 121px;
    height: 121px;
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}

:deep(.el-carousel__button) {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// 推荐商品模块
.recommendContent {
  width: 1150px;
  margin: 0 auto;

  .recommendGoodsContent {
    width: 100%;
    height: 100%;
    margin-top: 61px;

    .recommendGoodsContentUp {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .recommendGoodsContentUpImg {
        width: 732px;
        height: 352px;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }

        .recommendGoodsContentDownBtn {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;

          .shoppingCartBtn,
          .buyBtn {
            width: 175px;
            height: 47px;
            line-height: 47px;
            background: #1677ff;
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            margin: auto 8px;
            border-radius: 8px;
            position: relative;

            .quantityInCart {
              width: 30px;
              height: 30px;
              line-height: 30px;
              border-radius: 100%;
              background: red;
              color: #ffffff;
              position: absolute;
              right: -5px;
              top: -10px;
            }
          }

          .recommendGoodsContentDownBtnTetle {
            width: 137px;
            height: 47px;
            text-align: center;
            font-size: 16px;
            margin: auto 8px;
            // background-color: aqua;
            .itemSSR001 {
              width: 100%;
              height: 100%;
              // border: 1px solid red;
              // overflow: hidden;//溢出隐藏
              // text-overflow: ellipsis;//省略号
              // display: -webkit-box;//
              // -webkit-box-orient: vertical;//设置弹性盒子的子元素的排列方式
              // -webkit-line-clamp: 2;//设置显示文本的行数
            }
          }
        }
      }

      .recommendGoodsContentUpTitle {
        width: 380px;
        height: 416px;
        background: #1677ff;

        .recommendGoodsContentUpTitle_ul {
          width: 80%;
          height: 100%;
          list-style: disc;
          padding-left: 30px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
          font-size: 23px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none
          }

          li {
            border-bottom: 2px solid #ffffff;
            margin-bottom: 10px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .recommendGoodsContentDown {
      width: 100%;
      margin: 32px 0px;
      display: flex;
      justify-content: space-between;

      .recommendGoodsContentDownTitle {
        width: 380px;
        height: 416px;
        background: #1677ff;

        .recommendGoodsContentDownTitle_ul {
          width: 80%;
          height: 100%;
          list-style: disc;
          padding-left: 30px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
          font-size: 23px;

          li {
            border-bottom: 2px solid #ffffff;
            margin: 10px 0px;
          }
        }

        .recommendGoodsContentUpTitle_ul {
          width: 80%;
          height: 100%;
          list-style: disc;
          padding-left: 30px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: auto;
          font-size: 23px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none
          }

          li {
            border-bottom: 2px solid #ffffff;
            margin-bottom: 10px;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }

      .recommendGoodsContentDownImg {
        width: 732px;
        height: 352px;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }

        .recommendGoodsContentDownBtn {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: end;

          .shoppingCartBtn,
          .buyBtn {
            width: 175px;
            height: 47px;
            line-height: 47px;
            background: #1677ff;
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            margin: auto 8px;
            border-radius: 8px;
            position: relative;

            .quantityInCart {
              width: 30px;
              height: 30px;
              line-height: 30px;
              border-radius: 100%;
              background: red;
              color: #ffffff;
              position: absolute;
              right: -5px;
              top: -10px;
            }
          }

          .recommendGoodsContentDownBtnTetle {
            width: 137px;
            height: 47px;
            text-align: center;
            font-size: 16px;
            margin: auto 8px;
            // background-color: aqua;
            .itemSSR001 {
              width: 100%;
              height: 100%;
              // border: 1px solid red;
              // overflow: hidden;//溢出隐藏
              // text-overflow: ellipsis;//省略号
              // display: -webkit-box;//
              // -webkit-box-orient: vertical;//设置弹性盒子的子元素的排列方式
              // -webkit-line-clamp: 2;//设置显示文本的行数
            }
          }
        }
      }
    }
  }
}

.et_pb_section {
  background: #1F2A2C;
  width: 100%;
  box-sizing: content-box;
  border-bottom: 1px solid #000;

  .center {
    width: 1336px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 20px;

    .pb_section {

      border: 2px solid rgba(255, 255, 255, 0.3);
      border-radius: 20px;
      padding: 10px;
      transition: 1s;
      min-width: 230px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &:hover {
        border: 2px solid #00a6ff;

        box-shadow: 0 0 12px 3px #00a6ff;
      }

      .img {
        margin-bottom: 20px;

        img {
          width: 150px;
          object-fit: cover;
        }
      }

      .a_href {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: 20px;
            color: white;
            letter-spacing: 1px;
            transition: 0.5s;

            &:nth-of-type(1) {
              &:hover {
                letter-spacing: 2.5px;
              }
            }

            &:nth-last-child(1) {
              font-size: 18px;
              color: #3adbbd;
            }
          }
        }
      }
    }
  }


}

.EXCHANGES {
  background: #1F2A2C;

  width: 100%;
  height: 100%;
  margin: 0 auto -20px auto;

  .center {
    width: 1366px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 36px 0 10px 0;
    align-items: center;

    div.title {
      font-size: 20px;
      color: #FFF;
      width: 300px;
    }

    a {
      width: 300px;
      display: inline-block;
    }

    img {
      height: 30px;
    }
  }

}
</style>
