<template>
  <div class="echarts-box">
    <template v-if="showComponents">
      <NetworkHashrate />
    </template>
  </div>
</template>
<script setup>
import NetworkHashrate from "@/components/echarts/kapasEcharts/NetworkHashrate.vue";
import {onBeforeUnmount, ref} from 'vue'
const resizeTimeout = ref(null);
const showComponents = ref(true);

const reloadComponents = () => {
  showComponents.value = false;
  setTimeout(() => {
    showComponents.value = true;
  }, 500);
};

window.addEventListener('resize', () => {
  clearTimeout(resizeTimeout.value);
  resizeTimeout.value = setTimeout(() => {
    console.log('重新加载组件');
    reloadComponents();
  }, 1000);
});

onBeforeUnmount(() => {
  clearTimeout(resizeTimeout.value);
});
</script>
<style lang="scss" scoped>
.echarts-box {
  width: 100%;
  display: flex;

  div {
    margin-right: 25px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}
</style>