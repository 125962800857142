<template>
  <header>
    <div class="header">
      <div class="header_logo"><img src="../../../public/logo1.png" alt=""></div>
      <ul>
        <li
          v-for="(item, index) in store?.state?.nav?.headerList"
          @click="bthClick(item)"
          :key="index"
        >
          {{ store.state.language ? item.name : item.shortName }}
          <!-- <div v-if="item.name=='购物车'" class="cartNumber">{{data.goodsNumber}}</div> -->
          <el-badge :value="store.state.cartNumber" class="item" v-if="item.name=='购物车'">
          </el-badge>
        </li>
      </ul>
    </div>
  </header>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { inject, reactive,ref } from "vue";
import Common from "@/common/common.js";
import {
  getCartGoodsTotal,
} from "@/common/api.js";
let login = inject("login");
const store = useStore();
const router = useRouter();
let clickArr = ["我的账户", "购物车"];
const data = reactive({
  language: Common.language,
});
const bthClick = (item) => {
  //   console.log(Common.language)
  // console.log(item);
  if (item.navigatorID == "0b505ea95b104c59830bc70ea64cc11f") {
    Common.language = !Common.language;
    data.language = !data.language;
    store.commit('languageState', data.language)
  } else {
    router.push(item.functionURL);
  }
};

</script>
<style lang="scss" scoped>
header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 666;
  border-bottom: 1px solid #000000;
  background: #fff;
}

.header {
  width: 1366px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    height: 28px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
    padding-left: 60px;
  }

  > ul {
    display: flex;
    margin-right: 67px;

    li {
      margin-left: 54px;
      font-size: 18px;
      font-weight: bold;
      color: #545151;
      line-height: 23px;
      cursor: pointer;
      position: relative;
      .cartNumber{
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        background: red;
        color: #ffffff;
        position: absolute;
        right: -5px;
        top: -10px;
      }
      .item {
        margin-top: -10px;
      }
    }
  }
  .header_logo{
    height: 40px;
    width: 187px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
