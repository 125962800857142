import { getNavigatorList, getFocusList, getNavigatorSimpleArticleList } from "../../common/api"
import { ElMessage } from "element-plus";
import Common from "../../common/common";
import store from "@/store";
export default {
    namespaced: true,
    state() {
        return {
            // 导航信息
            navList: [],
            headerList: [],
            footerList: [],
            homeSwiper: [],
        }
    },
    mutations: {
        navId(state, data) {
            state.navList = data
        },
        footerId(state, data) {
            state.footerList = { ...data }
        },
        headerId(state, data) {
            state.headerList = { ...data }
        },
        homeId(state, data) {
            state.homeSwiper = data
        },
    },
    //这里定义异步操作和提交mutations的方法
    actions: {
        //获取导航列表
        getNavList(context) {
            for (let key in Common.navIdList) {
                getNavigatorList({
                    siteID: Common.siteID,
                    navigatorID: Common.navIdList[key],
                    sortTypeOrder: 1
                }, res => {
                    let data = []
                    if (res.data.header.code === 0) {
                        data = res.data.body.data.rows
                        if (key !== 'footerId') {
                            context.commit(key, data)
                            console.log('111',data);
                            return
                        }
                        data.forEach(item => {
                            getNavigatorSimpleArticleList({
                                navigatorID: item.navigatorID,
                                sortTypeOrder: 1
                            }, res => {
                                item.rows = res.data.body.data.rows
                                item.rows.forEach((it)=>{
                                    if(it.name.length>20){
                                        it.name=Common.getNewline(it.name,20)
                                        it.shortName=Common.getNewline(it.shortName,20)
                                        console.log(Common.getNewline(it.name,20));
                                    }
                                })
                                context.commit(key, data)
                                console.log('222333',data);
                            })
                        });
                    } else {
                        if(store.state.language){
                            ElMessage({
                                message: '请求失败',
                                type: 'error',
                            })
                        }else{
                            ElMessage({
                                message: 'request failure',
                                type: 'error',
                            })
                        }

                    }
                })
            }
        },
        //获取swiper
        getSwiper(context) {
            for (let key in Common.swiper) {
                getFocusList({
                    siteID: Common.siteID,
                    focusID: Common.swiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        if(store.state.language){
                            ElMessage({
                                message: '请求失败',
                                type: 'error',
                            })
                        }else{
                            ElMessage({
                                message: 'request failure',
                                type: 'error',
                            })
                        }
                    }
                })
            }
        },
        //获取导航列表
        getNavList2(context,shortName) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.headerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name=='我的账户'){
                            it.name=`${it.name}${shortName}`
                            it.shortName=`${it.shortName}${shortName}`
                        }
                    })
                    console.log('data',data);
                    context.commit('headerId', data)
                } else {
                    if(store.state.language){
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }else{
                        ElMessage({
                            message: 'request failure',
                            type: 'error',
                        })
                    }

                }
            })
        },
        //获取导航列表
        getNavList3(context,) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.footerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name.length>10){
                            Common.getNewline(item.name,16)
                            console.log(Common.getNewline(item.name,16));
                        }
                    })
                    console.log('data222',data);
                    context.commit('footerId', data)
                } else {
                    if(store.state.language){
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }else{
                        ElMessage({
                            message: 'request failure',
                            type: 'error',
                        })
                    }

                }
            })
        },
    }
}