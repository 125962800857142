<template>
  <div class="app">
    <Header/>
    <main ref="main">
      <router-view :key="router.fullPath"/>
    </main>
    <Footer/>
  </div>
</template>

<script setup>
import { inject,ref,onMounted} from "vue";
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';
import Header from "./components/header/header.vue"
import Footer from "./components/footer/footer.vue"
let Common = inject('Common')
console.log(Common)
console.log(process.env.VUE_APP_BASE_API)
let main = ref()
onMounted(()=>{
  main.value.style.minHeight = `${document.documentElement.clientHeight-473-80}px`
})
window.addEventListener('resize', ()=>{
  main.value.style.minHeight = `${document.documentElement.clientHeight-473-80}px`
});
const store = useStore();
const router = useRoute();
store.dispatch('user/getUserInfo');
store.dispatch('nav/getNavList');
store.dispatch('nav/getSwiper');
store.dispatch('nav/getNavList3')
setTimeout(() => {
  console.log(store.state)
}, 1000);
let str='ICERIVER是全球领先的加密货币挖矿设备制造商。我们致力于开发最高效的采矿产品，并提供最优质的服务。'
// Common.getNewline("csdn非常NB-你觉得呢",16);//当指定位置遇到汉字时，默认在后面换行
// console.log(Common.getNewline("csdn非常NB-你觉得呢",16));
</script>

<style lang="scss">
#app,
html,
body,
.app{
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
#app{
  padding-top: 80px;
}
.app{
  main{
    width: 100%;
  }
}
</style>
