<template>
  <footer>
<!--    <div class="et_pb_section">-->
<!--      <div class="pb_section">-->
<!--        <div class="img">-->
<!--          <a href="https://kaspa.org/" target="_blank"><img src="../../assets/img/Kaspa-Icon-Dark-Green-glow.webp"-->
<!--                                                            alt=""></a>-->
<!--        </div>-->
<!--        <div class="a_href">-->
<!--          <a href="https://kaspa.org/" target="_blank">-->
<!--            <span>{{ state.language ? 'Kaspa官网' : 'Kaspa official website' }}</span>-->
<!--            <span>{{ state.language ? 'kaspa.org' : 'kaspa.org' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="pb_section">-->
<!--        <div class="img">-->
<!--          <a href="https://explorer.kaspa.org/" target="_blank">-->
<!--            <img src="../../assets/img/k-icon-glow.png" alt="">-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="a_href">-->
<!--          <a href="https://explorer.kaspa.org/" target="_blank">-->
<!--            <span>{{ state.language ? 'KASPA浏览器' : 'KASPA explorer' }}</span>-->
<!--            <span>{{ state.language ? 'explorer.kaspa.org' : 'explorer.kaspa.org' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="pb_section">-->
<!--        <div class="img">-->
<!--          <a href="https://wallet.kaspanet.io/" target="_blank">-->
<!--            <img src="../../assets/img/kaspa-fwallet-icons.webp" alt="">-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="a_href">-->
<!--          <a href="https://wallet.kaspanet.io/" target="_blank">-->
<!--            <span>{{ state.language ? 'Kaspa官网钱包' : 'Kaspa Official website wallet' }}</span>-->
<!--            <span>{{ state.language ? 'wallet.kaspanet.io' : 'wallet.kaspanet.io' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="pb_section">-->
<!--        <div class="img">-->
<!--          <a href="https://onekey.so/" target="_blank">-->
<!--            <img src="../../assets/img/onekey_logo.png" alt="">-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="a_href">-->
<!--          <a href="https://onekey.so/" target="_blank">-->
<!--            <span>{{ state.language ? 'Onekey硬件钱包' : 'Onekey Hardware wallet' }}</span>-->
<!--            <span>{{ state.language ? 'onekey.so' : 'onekey.so' }}</span>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="EXCHANGES">-->
<!--      <div class="title">{{ state.language ? 'KASPA交易所' : 'KASPA EXCHANGES' }}</div>-->
<!--      <a href="https://www.kucoin.com/" target="_blank"><img src="../../assets/img/kucoin-logo.png" alt=""></a>-->
<!--      <a href="https://www.mexc.com/exchange/KAS_USDT" target="_blank"><img src="../../assets/img/MEXC-White.png"-->
<!--                                                                            alt=""></a>-->
<!--      <a href="https://www.coinex.com/exchange/KAS-USDT" target="_blank"><img src="../../assets/img/coinEX-White.png"-->
<!--                                                                              alt=""></a>-->
<!--      <a href="https://www.digifinex.com/en-ww/trade/USDT/KAS" target="_blank"><img-->
<!--          src="../../assets/img/digifinexwhite.png.png" alt=""></a>-->
<!--    </div>-->
    <div class="footer">
      <div v-for="(item, index) in state?.nav?.footerList" :key="index" style="width:300px;margin:0 10px;">
        <p> {{ state.language ? item.name : item.shortName }}</p>
        <ul>
          <li v-for="(ite, ind) in item.rows" :key='ind' @click="toPath(ite)">
            {{ state.language ? ite.title.replace( /<br>/g,''):ite.subTitle.replace(/<br>/g,'') }}
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script setup>
import {useStore} from 'vuex';
import {useRouter} from "vue-router"
import {ElMessage} from "element-plus";
import store from "@/store/index"

const {state} = useStore();
const router = useRouter();
console.log('888555222', state.nav.footerList);
const toPath = (ite) => {
  if (!ite.articleID) return
  if (ite.title.length > 20) {
    if (store.state.language) {
      ElMessage({
        message: "无内容",
        type: "warning",
      });
    } else {
      ElMessage({
        message: 'No Content',
        type: 'warning',
      })
    }

    return
  }
  router.push(`/articleDetails?articleID=${ite.articleID}`)
}

</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  //height: 473px;
  background: #1F2A2C;
}

.footer {
  width: 1366px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: aliceblue;

  > div {
    height: 100%;
    padding-top: 36px;

    > p {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 23px;
    }


    > ul {
      margin-top: 24px;

      li {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 23px;
        margin-bottom: 18px;
        cursor: pointer;
      }
    }
  }
}

.et_pb_section {
  background: #1F2A2C;
  display: flex;
  justify-content: space-around;
  width: 1336px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: content-box;
  border-bottom: 1px solid #000;

  .pb_section {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    padding: 10px;
    transition: 1s;
    min-width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      border: 2px solid #00a6ff;

      box-shadow: 0 0 12px 3px #00a6ff;
    }

    .img {
      margin-bottom: 20px;

      img {
        width: 150px;
        object-fit: cover;
      }
    }

    .a_href {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 20px;
          color: white;
          letter-spacing: 1px;
          transition: 0.5s;

          &:nth-of-type(1) {
            &:hover {
              letter-spacing: 2.5px;
            }
          }

          &:nth-last-child(1) {
            font-size: 18px;
            color: #3adbbd;
          }
        }
      }
    }
  }


}

.EXCHANGES {
  width: 1366px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  padding: 36px 0 10px 0;
  align-items: center;

  div.title {
    font-size: 20px;
    color: #FFF;
    width: 300px;
  }

  a {
    width: 300px;
    display: inline-block;
  }

  img {
    height: 30px;
  }
}
</style>